<template>
    <el-dialog title="抽奖纪录" :visible="visible" width="80%" :close-on-press-escape="false" :close-on-click-modal="false"
        @close="close">
        <div>
            <!--搜索-->
            <div class="page-content-search">
                <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="用户昵称">
                                <el-input v-model="search.filter_nickname"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="手机号">
                                <el-input v-model="search.filter_mobile"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="抽奖时间">
                                <el-date-picker v-model="searchtime" type="datetimerange" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd HH:mm"
                                    value-format="yyyy-MM-dd HH:mm">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="是否中奖">
                                <el-select v-model="search.filter_hit_flag" style="width: 100%">
                                    <el-option label="全部" :value=0></el-option>
                                    <el-option label="中奖" :value=1></el-option>
                                    <el-option label="未中奖" :value=2></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="奖品类型">
                                <el-select v-model="search.filter_gift_type" style="width: 100%">
                                    <el-option label="全部" :value=0></el-option>
                                    <el-option label="商品" :value=1></el-option>
                                    <el-option label="优惠券" :value=2></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="page-content-search-button" style="display:flex">
                        <el-col :span="24">
                            <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                            </el-button>
                            <el-button icon="el-icon-circle-close" size="medium" @click="clearSearch()">清空搜索</el-button>
                        </el-col>
                        <el-button v-if="is_auth('GET:/api/oss/games/roulette/<game_uuid>/excel')" icon="el-icon-download"
                            size="medium" type="primary" @click="exportOrder">导出
                        </el-button>
                    </el-row>

                </el-form>
            </div>
        </div>
        <div style="height: 20px"></div>
        <!--列表-->
        <el-table size="medium" border :data="tableData" style="width: 100%">
            <el-table-column prop="name" label="抽奖活动名称" width="200"> </el-table-column>
            <el-table-column prop="user_uuid" label="用户uuid" width="200">
            </el-table-column>
            <el-table-column prop="user_nickname" label="用户昵称" width="200" />
            <el-table-column prop="user_avatar" label="用户头像" min-width="80">
                <template slot-scope="scope">
                    <el-avatar shape="square" :size="22" :src="scope.row.user_avatar"></el-avatar>
                </template>
            </el-table-column>
            <el-table-column prop="time" label="是否中奖" width="80">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.hit_flag === 1" type="success">是</el-tag>
                    <el-tag v-else-if="scope.row.hit_flag === 0">否</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="user_mobile" label="用户手机号" width="180">
            </el-table-column>
            <el-table-column prop="state" label="奖品类型" width="100">
                <template slot-scope="scope">
                    <span v-if="scope.row.gift_type === 0"> - </span>
                    <template v-else>
                        <el-tag v-if="scope.row.gift_type === 1">商品</el-tag>
                        <el-tag v-else-if="scope.row.gift_type === 2">优惠券</el-tag>
                    </template>
                </template>
            </el-table-column>
            <el-table-column prop="gift_name" label="奖品名称" width="180">
            </el-table-column>
            <el-table-column prop="user_address" label="收货地址" width="180">
                <template slot-scope="scope">
                    <span v-if="scope.row.gift_type === 1"> {{ scope.row.user_address ? `${scope.row.user_address}` :
                        '未填写' }} </span>
                    <span v-else> - </span>
                </template>
            </el-table-column>
            <el-table-column prop="user_note" label="备注" width="180">
                <template slot-scope="scope">
                    <span v-if="scope.row.gift_type === 1"> {{ scope.row.user_note ? `${scope.row.user_note}` :
                        '未填写' }} </span>
                    <span v-else> - </span>
                </template>
            </el-table-column>
            <el-table-column prop="time" label="抽奖时间" width="180">
                <template slot-scope="scope">
                    <span>{{ handleTime(scope.row.created_at) }}</span>
                </template>
            </el-table-column>
        </el-table>
        <div style="height: 20px"></div>
        <!--分页-->
        <el-pagination @current-change="getlist" :page-size="this.env.pageSize" :pager-count="7" background
            layout="prev, pager, next, total" :current-page.sync="page" :total="count">
        </el-pagination>
    </el-dialog>
</template>


<script>
import help from '../../../../custom/help';
export default {
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            search: {},
            tableData: [],
            count: 0, // 数据总条数
            page: 1, // 当前页数
            game_uuid: '',
            searchtime: []
        }
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth);
        },
        init(id) {
            if (id) {
                this.search = {
                    filter_nickname: "",
                    filter_mobile: "",
                    filter_hit_flag: 0,
                    filter_gift_type: 0,
                };
                this.game_uuid = id;
                this.getlist();
            }
        },
        clearSearch() {
            this.search = {
                filter_nickname: "",
                filter_mobile: "",
                filter_hit_flag: 0,
                filter_gift_type: 0,
            };
            this.searchtime = [];
            this.getlist();
        },
        /**
        * 查看抽奖纪录
        */
        async getlist() {
            const { game_uuid, searchtime } = this;
            const postdata = {
                page: this.page,
                pagesize: this.env.pageSize
            };
            help.deepMerge(postdata, this.search);
            if (searchtime.length > 0) {
                postdata['filter_start_time'] = help.dateToTimestamp(searchtime[0]);
                postdata['filter_end_time'] = help.dateToTimestamp(searchtime[1]);
            }
            try {
                const res = await this.get(`games/roulette/${game_uuid}/logs`, postdata);
                console.log(res);
                if (res.code === 0) {
                    this.tableData = res.data.list;
                    this.count = res.data.count;
                } else {
                    this.$message.warning(res.message);
                }
            } catch (error) {
                this.loading = false;
                this.$message.error(error);
            }
        },
        handleTime(time) {
            return help.timestampToTime(time, 'time');
        },
        /**
         * 关闭弹窗
         */
        close() {
            this.$emit('close');
        },
        /**
         * 点击搜索
         */
        is_search() {
            this.page = 1;
            this.getlist();
        },
        async exportOrder() {
            const { game_uuid, searchtime } = this;
            const postdata = {
                page: this.page,
                pagesize: this.env.pageSize
            };
            help.deepMerge(postdata, this.search);
            if (searchtime.length > 0) {
                postdata['filter_start_time'] = help.dateToTimestamp(searchtime[0]);
                postdata['filter_end_time'] = help.dateToTimestamp(searchtime[1]);
            }
            try {
                const res = await this.get(`games/roulette/${game_uuid}/excel`, postdata);
                if (res.code === 0) {
                    location.href = res.data;
                } else {
                    this.$message.warning(res.message);
                }
            } catch (error) {
                this.loading = false;
                this.$message.error(error);
            }
        }


    }
}
</script>