<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>抽奖转盘</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--搜索-->
            <div v-show="issearch" class="page-content-search">
                <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="活动名称">
                                <el-input v-model="search.filter_name"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="状态">
                                <el-select v-model="search.filter_status" placeholder="请选择类型" style="width: 100%">
                                    <el-option label="全部" value="all"></el-option>
                                    <el-option label="进行中" :value="'in_progress'"></el-option>
                                    <el-option label="未开始" :value="'wait'"></el-option>
                                    <el-option label="已结束" :value="'completed'"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="创建时间">
                                <el-date-picker style="width: 70%" v-model="search.searchtime" type="daterange"
                                    range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                                    format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="page-content-search-button">
                        <el-col :span="24">
                            <el-form-item>
                                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                                </el-button>
                                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <!--标题-->
            <el-row>
                <el-col :span="12">
                    <h3>{{ page_name }}</h3>
                </el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
                            @click="issearch = !issearch">隐藏搜索
                        </el-button>
                        <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary"
                            @click="issearch = !issearch">显示搜索
                        </el-button>
                        <el-button v-if="is_auth('POST:/api/oss/games/roulettes')" icon="el-icon-plus" size="medium"
                            type="primary" @click="addUpdata()">添加
                        </el-button>
                    </div>
                </el-col>
            </el-row>
            <div style="height: 20px"></div>
            <!--列表-->
            <el-table size="medium" border v-loading="loading" :data="tableData" style="width: 100%">
                <el-table-column prop="game_uuid" label="活动ID" width="200" />
                <el-table-column prop="name" label="活动名称" width="200">
                </el-table-column>
                <el-table-column prop="user_scope" label="活动对象" width="140">
                    <template slot-scope="scope">
                        <el-tag> {{ scope.row.user_scope === 0 ? '所有用户' : '仅限新注册用户' }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="background_img" label="背景图片" min-width="200">
                    <template slot-scope="scope">
                        <el-image style="width: 22px; height: 22px; margin-right: 4px" :src="scope.row.background_img"
                            fit="contain" :preview-src-list="[scope.row.background_img]">
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="time" label="活动生效时间" width="220">
                    <template slot-scope="scope">
                        <span>{{ handleTime(scope.row.show_start_time) }}至{{ handleTime(scope.row.show_end_time) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="create_at" label="活动抽奖时间" width="220">
                    <template slot-scope="scope">
                        <span>{{ handleTime(scope.row.game_start_time) }}至{{ handleTime(scope.row.game_end_time) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="button_img" label="按钮图片" width="100">
                    <template slot-scope="scope">
                        <el-image style="width: 22px; height: 22px; margin-right: 4px" :src="scope.row.button_img"
                            fit="contain" :preview-src-list="[scope.row.button_img]">
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="time" label="参与次数" width="180">
                    <template slot-scope="scope">
                        <span v-if="scope.row.total_type === 0">{{ `每人每天 ${scope.row.type_num} 次` }}</span>
                        <span v-if="scope.row.total_type === 1">{{ `每人共可免费抽 ${scope.row.type_num} 次` }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="share_num" label="分享设置" width="180">
                    <template slot-scope="scope">
                        <span>{{ scope.row.share_num }}次</span>
                    </template>
                </el-table-column>
                <el-table-column prop="time" label="中奖数量/奖品总数" width="180">
                    <template slot-scope="scope">
                        <span>{{ scope.row.gifts_total - scope.row.gifts_over }}/{{ scope.row.gifts_total }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="time" label="状态" width="180">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.state === 0">正常</el-tag>
                        <el-tag v-if="scope.row.state === 1" type="danger">已禁用</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="created_at" label="创建时间" width="180">
                    <template slot-scope="scope">
                        <span>{{ handleTime(scope.row.created_at, 'time') }}</span>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="340">
                    <template slot-scope="scope">
                        <el-button v-if="is_auth('GET:/api/oss/games/roulette/<game_uuid>/profile')" size="mini"
                            @click="onNavigateDetail(scope.row.game_uuid)">查看
                        </el-button>
                        <el-button v-if="is_auth('POST:/api/oss/games/roulette/<game_uuid>/profile')" type="primary"
                            size="mini" @click="onEdit(scope.row.game_uuid)">编辑
                        </el-button>
                        <el-button v-if="scope.row.state === 1 && is_auth('POST:/api/oss/games/roulette/<game_uuid>/state')"
                            @click="operation_state(scope.row, 0)" type="primary" size="mini">启用
                        </el-button>
                        <el-button
                            v-else-if="scope.row.state === 0 && is_auth('POST:/api/oss/games/roulette/<game_uuid>/state')"
                            @click="operation_state(scope.row, 1)" type="danger" size="mini">禁用
                        </el-button>
                        <el-button v-if="is_auth('GET:/api/oss/games/roulette/<game_uuid>/logs')"
                            @click="seeRecord(scope.row.game_uuid)" type="primary" size="mini">查看抽奖纪录
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height: 20px"></div>
            <!--分页-->
            <el-pagination @current-change="getlist" :page-size="this.env.pageSize" background
                layout="prev, pager, next, total" :current-page.sync="page" :total="count">
            </el-pagination>
        </div>
        <record ref="record" :visible="recordVisible" @close="recordVisible = false" />
    </div>
</template>
<script>
import Record from './component/record.vue';
import help from './../../../custom/help';
export default {
    components: { Record },
    data() {
        return {
            page_name: "抽奖活动列表",
            loading: true, // 加载状态
            issearch: false, // 搜索是否展示
            search: {}, // 搜索内容
            count: 0, // 数据总条数
            page: 1, // 当前页数
            tableData: [], // 列表内容
            recordVisible: false
        };
    },
    // 创建
    created() {
        this.init();
    },
    // 安装
    mounted() { },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth);
        },
        // 初始化
        init() {
            this.search = {
                filter_name: "",
                filter_status: "",
                searchtime: []
            };
            this.is_search();
        },
        //搜索
        is_search() {
            this.page = 1;
            this.getlist();
        },
        // 获取列表
        async getlist() {
            this.loading = true;
            const postdata = {
                page: this.page,
                pagesize: this.env.pageSize
            }
            help.deepMerge(postdata, this.search);
            if (postdata.searchtime.length > 0) {
                postdata['filter_start_time'] = help.dateToTimestamp(postdata.searchtime[0]);
                postdata['filter_end_time'] = help.dateToTimestamp(postdata.searchtime[1]);
                delete postdata.searchtime;
            }
            try {
                const res = await this.get('games/roulettes', postdata);
                console.log(res);
                this.loading = false;
                if (res.code === 0) {
                    this.tableData = res.data.list;
                    this.count = res.data.count;
                } else {
                    this.$message.warning(res.message);
                }
            } catch (error) {
                this.loading = false;
                this.$message.error(error);
            }
        },
        /**
         * 查看抽奖纪录
         */
        async seeRecord(id) {
            this.recordVisible = true;
            this.$refs.record.init(id);
        },
        // 添加/编辑
        addUpdata(id) {
            let json = {
                path: "/weapphome/lottery/edit",
            };
            if (id) {
                json["query"] = {
                    id,
                };
            }
            this.$router.push({ ...json });
        },
        operation_state(item, state) {
            let tip = "";
            let options = {};
            // 启用
            if (state === 0) {
                tip = `启用【${item.name}】活动？`;
            }
            // 禁用
            if (state === 1) {
                tip = `禁用【${item.name}】活动？`;
            }

            // 弹出二次确认
            this.$confirm(tip, "确认信息", options)
                .then(() => {
                    this.isoperation(item.game_uuid, state);
                })
                .catch(() => { });
        },
        // 操作
        async isoperation(id = "", state = "") {
            try {
                const res = await this.post(`games/roulette/${id}/state`, { state });
                if (res.code === 0) {
                    this.$message({
                        message: "操作成功",
                        type: "success",
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist(); // 刷新列表(重新搜索)
                        },
                    });
                }
            } catch (error) {
                this.$message.error(error);
            }
        },
        handleTime(time, type = '') {
            return help.timestampToTime(time, type);
        },
        /**
         * 前往活动查看页
         * @param {string} id 活动id
         */
        onNavigateDetail(id) {
            this.$router.push({ path: '/weapphome/lottery/details', query: { id } });
        },
        /**
         * 前往活动修改页
         * @param {string} id 活动id
         */
        onEdit(id) {
            this.$router.push({ path: '/weapphome/lottery/edit', query: { id } });
        }
    },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*表格不换行*/
>>>.el-table__body .cell {
    white-space: nowrap;
    /*overflow: initial;*/
}

.form-tag_colour-div {
    min-height: 200px;
}
</style>
  